<template>
  <div class="createInvoice">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Download Invoice Data</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading">
        <v-form v-model="valid" class="ma-2" @submit.prevent>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedCompanies"
                  :items="companies"
                  return-object
                  item-text="name"
                  label="Select Companies"
                  multiple
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  block
                  outlined
                  :loading="loading"
                  @click="selectAllCompanies"
                  >Select all</v-btn
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedInvoice"
                  :items="invoiceNumbers"
                  item-text="invoiceNumbers"
                  label="Select Invoice Number"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="selectedCompanies.length > 0 && selectedInvoice"
              >
                <v-btn
                  class="ml-1 mr-1"
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="createCompanyInvoice()"
                  >Download Billing Data</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-card
        v-if="companyBilliingData.length > 0"
        class="px-2 py-2 pb-0 my-2"
        outlined
      >
        <v-container>
          <v-row>
            <v-btn
              @click="applyMinimumCharge()"
              :loading="loading"
              class="mb-2 v-size--small"
              color="orange lighten-3"
              >Apply Minimum Charge</v-btn
            >
            <v-btn
              @click="
                exportToExcel(
                  'xlsx',
                  exportXeroFile,
                  exportIndividualDetailedData,
                  exportGoCardless
                )
              "
              type="button"
              :disabled="loading"
              class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
              >Excel</v-btn
            >
            <v-btn
              @click="
                exportToExcel(
                  'csv',
                  exportXeroFile,
                  exportIndividualDetailedData,
                  exportGoCardless
                )
              "
              type="button"
              :disabled="loading"
              class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
              >CSV</v-btn
            >
            <v-btn
              @click="finalCommitBillingData()"
              type="button"
              :loading="loading"
              class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small primary"
              >Commit Final Billing Data</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="createSalesSummary('xlsx')"
              type="button"
              :disabled="loading"
              color="teal"
              class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small white--text"
              >Create Sales Summary</v-btn
            >
          </v-row>
          <v-row>
            <v-col cols="1">
              <b>Exporting:</b>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="mt-0"
                v-model="exportCombinedDetailedData"
                label="Combined Detailed"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="mt-0"
                v-model="exportIndividualDetailedData"
                label="Individual Detailed"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox class="mt-0" v-model="exportXeroFile" label="Xero">
              </v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                class="mt-0"
                v-model="exportGoCardless"
                label="Go cardless"
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-dialog v-model="dialog" max-width="75vw">
        <v-card>
          <v-card-title>
            <span class="headline">Edit {{ editedItem.shipment_id }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <charge-form
                :newCharge="editedItem"
                :editItemId="true"
              ></charge-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        class="mb-6"
        v-for="company in companyBilliingData"
        v-bind:key="company.id"
      >
        <v-card v-if="combinedChargeCodes.length > 0" class="mb-4">
          <v-container>
            <h2 class="title">{{ companyName }} Summary</h2>
            <div v-for="charge in combinedChargeCodes" v-bind:key="charge.id">
              {{ charge.code }} - {{ charge.name }} -
              {{ renderCurrency(charge.amount) }}
            </div>
          </v-container>
        </v-card>
        <div class="snapBillingTable" v-if="company.data.length > 0">
          <v-card>
            <v-card-title>
              {{ company.company_name }} Billing Data
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="snapHeaders"
              :items="company.data"
              :items-per-page="5"
              :search="search"
              id="data-table"
            >
              <template v-slot:item.pick_cost="{ item }">
                <v-chip dark>{{ renderCurrency(item["pick_cost"]) }}</v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
                <v-icon small @click="deleteItem(item)">delete</v-icon>
                <v-checkbox
                  v-model="selectedItemsToDelete"
                  v-bind:value="item.id"
                ></v-checkbox>
              </template>
            </v-data-table>
          </v-card>
          <div align="center" class="ma-7">
            <v-btn
              v-show="selectedItemsToDelete.length > 0"
              @click="confirmDeleteSelectedItems()"
              :disabled="loading"
              color="red darken-4"
              class="ml-2 mb-2 v-btn v-btn--depressed theme--light v-size--small white--text"
              >Delete Selected Charges</v-btn
            >
          </div>
        </div>
      </div>

      <!--dialog Overlay for minimum charge messages -->
      <v-dialog v-model="minimumChargeDialog" width="800">
        <v-card class="pa-2">
          <v-card-title>
            <span class="text-h5">Minimum Charge Results</span>
          </v-card-title>
          <div
            class="px-5 pb-5"
            v-if="minimumChargeResults && minimumChargeResults.length"
          >
            <v-card-text
              class="my-0 py-1 font-weight-bold"
              v-for="(result, index) in minimumChargeResults"
              :key="index"
            >
              {{ result }}
            </v-card-text>
          </div>
          <v-card-text v-else> No results to display </v-card-text>
          <v-card-actions>
            <span
              class="text-subtitle-2"
              v-if="minimumChargeResults && minimumChargeResults.length"
            >
              Note that <b>company settings not set up</b> and
              <b>no minimum charge to apply</b> errors can be ignored for
              companies that do not require minimum charges
            </span>
            <v-spacer />
            <v-btn
              color="primary"
              variant="text"
              @click="minimumChargeDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="snackbar" :timeout="5000">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false"></v-btn>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import CompaniesUtil from "@/services/CompaniesUtil.js";
import CommitedData from "@/services/CommitedData.js";
import AdhocCharges from "@/services/AdhocCharges.js";
import BillingSnap from "@/services/BillingSnap.js";
import ChargeForm from "../components/forms/ChargeForm";
import * as XLSX from 'xlsx'
import BillingCommonFunctions from "@/mixins/BillingCommonFunctions";

export default {
  mixins: [BillingCommonFunctions],
  components: {
    ChargeForm,
  },
  data() {
    return {
      // Loading
      loading: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      // Dialog
      dialog: false,
      errors: {
        shipment_id: null,
        pick_cost: null,
        charge_code: null,
        date_applied: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => typeof value == Number || "Value must be a number",
      },
      // Upload
      search: "",
      data: [],
      // Company
      companies: [],
      selectedCompanies: [],
      selectedCompaniesFull: [],
      companyName: null,
      valid: false,
      // Query
      query: {
        companyCode: null,
        companyId: null,
        dateShippedFrom: null,
        dateShippedTo: null,
        setInvoiceDate: null,
      },
      queryRun: false,
      // Billing Data
      snapBillingData: [],
      sortedBillingData: [],
      adhocCharges: [],
      processedData: [],
      // Company Margins
      companyMargins: [],
      // Snap Table
      snapHeaders: [
        { text: "Client", value: "company_code" },
        { text: "Charge Date", value: "charge_date" },
        { text: "Charge Type", value: "charge_type" },
        { text: "Reference", value: "reference" },
        { text: "Client Reference", value: "client_reference" },
        { text: "UOM", value: "UOM" },
        { text: "Billable Units", value: "billable_units" },
        { text: "Tariff", value: "tariff" },
        { text: "Total Charge", value: "total_charge" },
        { text: "Charge Code", value: "charge_code" },
        { text: "Actions", value: "action", sortable: false },
      ],
      // Edit
      editedIndex: null,
      editedItem: [],
      // Return Message
      returnMessage: "",
      // Charge Codes
      chargeCodes: [],
      selectedChargeCode: null,
      chargeCode: null,
      // Xero Export
      xeroExportData: [],
      // Combined Charge Codes
      combinedChargeCodes: [],
      // Check Invoice Date Set
      invoiceDateSet: true,
      // Combined Data
      companyBilliingData: [],
      // Combined Xero
      xeroBillingData: [],
      // Invoice Numbers
      selectedInvoice: null,
      invoiceNumbers: [],
      goCardlessTemplate: [],
      currentLoadedInvoiceNumber: null,
      selectedItemsToDelete: [],
      exportCombinedDetailedData: true,
      exportIndividualDetailedData: true,
      exportXeroFile: true,
      exportGoCardless: true,
      companyBillingSettings: [],

      // minimum charge variables
      minimumChargeResults: [],
      minimumChargeDialog: false,
    };
  },
  methods: {
    applyMinimumCharge() {
      try {
        if (!this.selectedCompanies || this.selectedCompanies.length < 1) {
          throw new Error("No companies selected");
        }
        if (!this.selectedInvoice) {
          throw new Error("No invoice selected");
        }
        let companyIds = this.selectedCompanies.map((comp) => comp.id);
        let confirmMsg = `Apply minimum charge for ${companyIds.length} companies?`;
        if (!confirm(confirmMsg)) return;

        this.minimumChargeResults = [];
        this.loading = true;
        let reqBody = {
          company_ids: companyIds,
          invoice_number: this.selectedInvoice,
        };
        BillingSnap.applyMinimumCharge(reqBody)
          .then((response) => {
            this.loading = false;
            this.minimumChargeDialog = true;
            this.minimumChargeResults = response;
            this.createCompanyInvoice();
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response.data.message;
            this.loading = false;
          });
      } catch (err) {
        this.snackbar = true;
        this.text = err;
        this.loading = false;
      }
    },

    getAllCompanyBillingSettings() {
      this.loading = true;
      BillingSnap.getCompanyBillingSettings()
        .then((response) => {
          this.companyBillingSettings = response;
        })
        .catch(() => {
          this.snackbar = true;
          this.text = "Failed to get company billing settings";
          this.loading = false;
        });
    },
    selectAllCompanies() {
      // Copy all v-select's items in your selectedItem array
      this.selectedCompanies = this.companies.filter(
        (comp) => comp.code !== "3PL" && comp.code !== "V08"
      );
    },
    // Edit & Delete
    async getChargeCodes(companyId) {
      // Use the BillingSnap to call the getBillingData() method
      return AdhocCharges.getChargeCodes(companyId)
        .then(
          ((chargeCodes) => {
            this.$set(this, "chargeCodes", chargeCodes);
            this.companies = this.sortFunc(this.companies);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    setChargeCode() {
      if (this.editedItem) {
        let editChargeCode = this.chargeCodes.find(
          (code) => code.name === this.selectedChargeCode
        );
        this.editedItem.charge_code = editChargeCode.code;
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    editItem(item) {
      let selectedCompany = this.companyBilliingData.find(
        (company) => company.company_code == item.company_code
      );
      this.editedItem = {
        id: item.id,
        company_id: selectedCompany.company_id,
        client: selectedCompany.company_code,
        chargeDate: item.charge_date,
        chargeType: item.charge_type,
        reference: item.reference,
        clientReference: item.client_reference,
        UOM: item.UOM,
        billableUnits: item.billable_units,
        tariff: item.tariff,
        totalCharge: item.total_charge,
        chargeCode: item.charge_code.toString(),
        invoiceNumber: this.currentLoadedInvoiceNumber,
      };
      this.selectedChargeCode = this.editedItem.charge_code;
      this.dialog = true;
    },
    save() {
      CommitedData.updateCharge(this.editedItem).then((response) => {
        if (response.status > 202) {
          this.snackbar = true;
          this.text = `Error: ${response.errorMessage[0]}`;
          this.loading = false;
          return false;
        } else {
          this.text = `Edited ${response.successMessage[0]} successfully.`;
          this.close();
          setTimeout(() => {
            this.snackbar = true;
            this.createCompanyInvoice();
          }, 750);
        }
      });
    },
    async deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
        urlencoded.append("id", item.id);
        CommitedData.deleteCharge(urlencoded).then(
          ((returnMessage) => {
            this.snackbar = true;
            this.text = `Deleted ${item.reference} from Committed Data.`;
            this.$set(this, "returnMessage", returnMessage);
            this.loading = false;
            setTimeout(() => {
              this.createCompanyInvoice();
            }, 300);
          }).bind(this)
        );
      }
    },
    async confirmDeleteSelectedItems() {
      let counter = this.selectedItemsToDelete.length;
      if (
        confirm(
          "Are you sure you want to delete the selected " + counter + " items"
        )
      ) {
        this.snackbar = true;
        this.text = "Deleting Charges";
        this.deleteSelectedItems(counter);
      }
    },
    async deleteSelectedItems(counter) {
      this.loading = true;
      if (this.selectedItemsToDelete.length > 0) {
        let urlencoded = new URLSearchParams();
        urlencoded.append("API-KEY", process.env.VUE_APP_FUSION_API_KEY);
        urlencoded.append("id", this.selectedItemsToDelete[0]);
        CommitedData.deleteCharge(urlencoded).then(() => {
          this.selectedItemsToDelete.shift();
          if (counter > 1) {
            this.deleteSelectedItems(counter - 1);
          } else {
            this.loading = false;
            this.snackbar = true;
            this.text = "Successfully Deleted Charges";
            setTimeout(() => {
              this.createCompanyInvoice();
            }, 150);
          }
        });
      }
    },
    formatCompanyName(companyCode, originalName) {
      switch (companyCode) {
        case "FLL":
          return "FairyLoot LTD";
        case "PLO":
          return "Precious Little One Ltd";
        case "SSI":
          return "Spectrum Supplements Inc";
        case "THE":
          return "The Bootbuddy LTD";
        case "SUP":
          return "Superfeet Worldwide UK LTD";
        case "SLM":
          return "Silver Mushroom Ltd";
        case "SIM":
          return "Simplywire Ltd";
        case "SIG":
          return "SIGG Switzerland Bottles AG";
        case "REV":
          return "Revolution Foods LTD";
        case "MYG":
          return "My Goodness Ltd";
        case "LFM":
          return "Worldwide Roar Ltd";
        case "ECO":
          return "Ecolab Ltd";
        case "CYC":
          return "Clear Cycle Ltd";
        case "CLE":
          return "Clear Cycle Ltd";
        case "CTW":
          return "Catwalk Collection";
        case "COR":
          return "Cordstrap Limited";
        case "CHI":
          return "Chiaro Technology Ltd";
        case "ACC":
          return "Accentuate Games Ltd";
        case "2NA":
          return "NATURAL ORGANICS INC";
        case "1PR":
          return "PreSonus";
        case "1GA":
          return "Gargoyle Distribution Limited";
        case "11D":
          return "11 Degrees Ltd";
        case "CDF":
          return "CD Foods";
        case "HAP":
          return "Happiest Baby INC.";
        case "WEL":
          return "Welspun UK";
        case "NAT":
          return "Natural Cycles Nordics AB";
        case "SW4":
          return "loomsbury Mill Ltd";
        case "ANV":
          return "Ingram Micro Services LTD";
        case "SOC":
          return "LINKS Logistics GmbH";
        case "V02":
          return "Clever Company Limited";
        case "HEN":
          return "Odlo Wind UK Ltd";
        case "V13":
          return "Grasper Global Inc";
        case "V16":
          return "Next Up Brands Ltd";
        case "V17":
          return "Stratton Brandhouse Ltd - Reprimo/Republica";
        case "ANN":
          return "Annes Day Ltd";
        case "SHE":
          return "Sheridan UK Limited";
        case "V15":
          return "Purifas Limited";
        case "V22":
          return "IStrap UK Limited";
        case "V23":
          return "UP Fitness Limited";
        case "FMW":
          return "FM World Logistics";
        default:
          return originalName;
      }
    },
    async getXeroAndDetailedData() {
      const lastInvoiceOfMonth = this.checkIfLastInvoiceOfMonth(
        this.selectedInvoice
      );
      // both xero and detailed data will be by default weekly data, so we need to filter the weekly data out for customers which are flagged for monthly billing
      // when its the last invoice of the month however,we then get the monthly data for the monthly clients and add this to the results
      let monthlyDetailedData = [];
      let monthlyXeroData = [];
      if (lastInvoiceOfMonth) {
        let companyIds = this.selectedCompanies.reduce((filtered, comp) => {
          if (this.monthlyCompanies.includes(comp.code)) {
            filtered.push(comp.id);
          }
          return filtered;
        }, []);
        if (companyIds && companyIds.length) {
          this.selectedCompanies.filter((comp) =>
            this.monthlyCompanies.includes(comp.code)
          );
          monthlyXeroData = await this.getCompanyBillingXero(
            companyIds,
            this.selectedInvoice,
            true
          );
          monthlyDetailedData = await this.getCompanyBillingCommitted(
            companyIds,
            this.selectedInvoice,
            true
          );
        }
      }

      let weeklyDetailedData = this.companyBilliingData.filter(
        (company) => !this.monthlyCompanies.includes(company.company_code)
      );
      let weeklyXeroData = this.xeroBillingData.filter(
        (company) => !this.monthlyCompanies.includes(company.company_code)
      );

      return {
        lastInvoiceOfMonth: lastInvoiceOfMonth,
        xeroData: weeklyXeroData.concat(monthlyXeroData),
        detailedData: weeklyDetailedData.concat(monthlyDetailedData),
      };
    },
    // Export Settings
    async exportToExcel(
      type,
      exportXeroFile,
      exportIndividualDetailedData,
      exportGoCardless
    ) { 
      const xeroAndDetailedData = await this.getXeroAndDetailedData();
      if (this.exportCombinedDetailedData || exportIndividualDetailedData) {
        this.generateDetailedFile(
          xeroAndDetailedData.detailedData,
          type,
          exportIndividualDetailedData
        );
      }
      if (exportXeroFile || exportGoCardless) {
        //  process to split xero into weekly and monthly companies
        this.generateXeroFile(
          xeroAndDetailedData.xeroData,
          type,
          this.exportGoCardless,
          xeroAndDetailedData.lastInvoiceOfMonth
        );
      }
    },
    generateDetailedFile(billingData, type, exportIndividualDetailedData) {
      let detailedData = [];

      let writeCounter = 0;
      billingData.forEach((company) => {
        if (company.data.length > 0) {
          let tempArray = [];
          let invoiceNumber = this.monthlyCompanies.includes(
            company.company_code
          )
            ? this.getMonthlyInvoiceNumberXero(
                company.company_code,
                this.selectedInvoice
              )
            : this.selectedInvoice;

          company.data.forEach((row) => {
            let tempItem = {
              company_code: row.company_code,
              charge_date: row.charge_date,
              charge_type: row.charge_type,
              reference: row.reference,
              client_reference: row.client_reference,
              UOM: row.UOM,
              billable_units: row.billable_units,
              tariff: row.tariff,
              total_charge: row.total_charge,
              charge_code: row.charge_code,
              invoiceNumber: invoiceNumber,
            };
            if (row.company_code === "WEL") {
              tempItem.Week = this.getWeekNumber(row.charge_date);
            }
            tempArray.push(tempItem);
            detailedData.push(tempItem);
          });
          if (exportIndividualDetailedData) {
            var elt = tempArray;
            var wb = XLSX.utils.book_new();
            var ws = XLSX.utils.json_to_sheet(elt);
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              company.company_code + " Detailed Data"
            );

            writeCounter++;
            setTimeout(() => {
              this.writeFile(wb, company.company_code + "DetailedData." + type);
            }, 1 + 500 * writeCounter);
            // XLSX.writeFile(wb, company.company_code + "DetailedData." + type);
          }
        }
      });
      if (detailedData.length > 0 && this.exportCombinedDetailedData) {
        var wb2 = XLSX.utils.book_new();
        var ws2 = XLSX.utils.json_to_sheet(detailedData);
        XLSX.utils.book_append_sheet(wb2, ws2, "Combined Detailed Data");
        writeCounter++;
        setTimeout(() => {
          this.writeFile(wb2, "Combined Detailed Data." + type);
        }, 1 + 500 * writeCounter);
        // XLSX.writeFile(wb2, "CombinedDetailedData." + type);
      }
    },
    async createSalesSummary(type){
      // we need to split the data into monthly and weekly customers because we want the monhtly to be at the bottom
      const monthlyXero = this.xeroBillingData.reduce((filtered, xero) => {
          if (this.monthlyCompanies.includes(xero.company_code)) {
            filtered.push(xero);
          }
          return filtered;
        }, []);
      
      const weeklyXero = this.xeroBillingData.reduce((filtered, xero) => {
          if (!this.monthlyCompanies.includes(xero.company_code)) {
            filtered.push(xero);
          }
          return filtered;
        }, []);
      const combinedXero = weeklyXero.concat(monthlyXero)

      this.generateSalesSummaryFile(type, combinedXero, "SalesSummary")

      this.generateDetailedFile(this.companyBilliingData, type, false);
    

    },
    
    async generateSalesSummaryFile(type, xeroData, filename) {
      
      const xeroExportData = [];

      //sales summary variables
      let summaryReport = [];
      let grandTotals = {
        Company: "Grand Total",
        Warehousing: 0,
        Distribution: 0,
      };
      let globalTotalAmount = 0;

      //for each xeroBilling Company generate xero report and sales summary
      for (const company of xeroData) {
        company.company_name = this.formatCompanyName(
          company.company_code,
          company.company_name
        );

        if (company.data.length > 0) {
          company.data.forEach((element) => {
            let tempArray = {
              ContactName: company.company_name,
              EmailAddress: null,
              POAddressLine1: null,
              POAddressLine2: null,
              POAddressLine3: null,
              POAddressLine4: null,
              POCity: null,
              PORegion: null,
              POPostalCode: null,
              POCountry: null,
              InvoiceNumber: `${company.company_code}-${element.invoice_number}`,
              Reference: null,
              InvoiceDate: element.invoice_date
                ? element.invoice_date.substring(0, 10)
                : "",
              DueDate: element.due_date
                ? element.due_date.substring(0, 10)
                : "",
              Total: null,
              InventoryItemCode: null,
              Description: element.description,
              Quantity: 1,
              UnitAmount: parseFloat(element.unit_amount),
              Discount: null,
              AccountCode: element.account_code,
              TaxType: element.tax_type,
              TaxAmount: null,
              TrackingName1: element.tracking_name1,
              TrackingOption1: element.tracking_option1,
              TrackingName2: null,
              TrackingOption2: null,
              Currency: null,
              BrandingTheme: null,
            };

            xeroExportData.push(tempArray);
          });

          //sales summary part
          let summaryArray = {
            Company: company.company_name,
            Warehousing: 0,
            Distribution: 0,
          };
          let chargeCodes = await this.getChargeCodes(company.company_id);
          if (!chargeCodes) {
            this.snackbar = true;
            this.text = `Failed to get charge codes for selected ${company.company_name}`;
            this.loading = false;
            return;
          }

          let combinedCharges = 0;
          //loop through all charge codes, compute amounts and add totals
          this.chargeCodes.forEach((chargeCode) => {
            if (chargeCode.code && chargeCode.TrackingOption1) {
              let record = company.data.find(
                (element) => element.account_code == chargeCode.code
              );
              let salesSummaryGrouping = this.getSalesSummaryGrouping(
                chargeCode.name
              );
              let amount = record ? parseFloat(record.unit_amount) : 0;
              summaryArray[salesSummaryGrouping] = summaryArray[
                salesSummaryGrouping
              ]
                ? summaryArray[salesSummaryGrouping] + amount
                : amount;

              combinedCharges += amount;

              //Add to grandTotals Array

              grandTotals[salesSummaryGrouping]
                ? (grandTotals[salesSummaryGrouping] += amount)
                : (grandTotals[salesSummaryGrouping] = amount);
              globalTotalAmount += amount;
            }
          });

          summaryArray["Grand Total"] = combinedCharges;
          summaryReport.push(summaryArray);
        }
      }

      grandTotals["Grand Total"] = globalTotalAmount;
      // save headers
      let headers = {};
      for (const header of Object.keys(grandTotals)) {
        headers[header] = header;
      }
      summaryReport.unshift(headers);
      summaryReport.unshift(grandTotals);

      if (xeroExportData.length > 0) {
        var elt3 = xeroExportData;
        var wb3 = XLSX.utils.book_new();
        var ws3 = XLSX.utils.json_to_sheet(elt3);
        var firstSheet = XLSX.utils.json_to_sheet(summaryReport, {
          skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb3, firstSheet, "Sales Summary");
        XLSX.utils.book_append_sheet(wb3, ws3, "Xero Data");
        XLSX.writeFile(wb3, `${filename}.${type}`);
      }
    },
    parseDateString(dateString) {
        const datesplit = dateString.split(', ');
        const [day, month, year] = datesplit[0].split('/').map(Number);
       
        return new Date(Date.UTC( year, month - 1, day, 0, 0, 0 ));
    },
    formatDateToDDMMYY(date) {
        // Get the day, month, and year from the date
        let day = date.getUTCDate();
        let month = date.getUTCMonth() + 1; // Months are zero-based
        let year = date.getUTCFullYear() % 100; // Get the last two digits of the year

        // Pad day and month with leading zeros if necessary
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        year = year < 10 ? '0' + year : year;

        // Format the date as dd/mm/yy
        return `${day}/${month}/${year}`;
    },
    getXeroDateBoundaries(dateStr, monthly) {
      let date = this.parseDateString(dateStr)
      if(monthly){
         // Clone the date to avoid mutating the original date
        const start = new Date(Date.UTC( date.getFullYear(), date.getMonth(), 1));

        // Get the day of the week for the first day of the month (0 is Sunday, 6 is Saturday)
        const firstDayOfWeek = start.getDay();
        if(firstDayOfWeek < 6){
          // get the sunday of the first invoice date (for example if friday is on the first day of the month, 
          // we need to get the sunday from that week even though it falls under another month)
          const daysToRemove = firstDayOfWeek

          start.setDate( start.getDate() - daysToRemove);
        }else{
          start.setDate( 2);
        }

        const end = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate() ))
        end.setDate(end.getDate() + 1);
        return {start, end}
      }

      // for normal dates
       // Get the previous Sunday
        const start = new Date(date);
        const currentDay = start.getDay();
        const diffToSunday = currentDay;  // No. of days from Sunday
        const diffToSaturday = 6 - currentDay;  // No. of days to Saturday
        start.setDate(start.getDate() - diffToSunday);

        // Get the next Saturday
        const end = new Date(date);

        end.setDate(date.getDate() + diffToSaturday);
        return {start, end}

     
    },
   

    getXeroDateBoundariesStr(companyCode, invoiceDate,  lastInvoiceOfMonth){
      let monthly = false 
      if(lastInvoiceOfMonth){
        monthly = this.monthlyCompanies.includes(companyCode)
      }
      let dates = this.getXeroDateBoundaries(invoiceDate, monthly)
      
      return ` - From ${this.formatDateToDDMMYY(dates.start)} to ${this.formatDateToDDMMYY(dates.end)}`
    },

    getChargeDate(dateStr){
       let chargeDate = dateStr.substr(0, 10);
          chargeDate = chargeDate.split("/");
          chargeDate = new Date(
            chargeDate[2],
            chargeDate[1] - 1,
            chargeDate[0]
          );
      chargeDate.setDate(chargeDate.getDate() + 14);
      //format date
          chargeDate = chargeDate
            .toJSON()
            .slice(0, 10)
            .split("-")
            .reverse()
            .join("/");
        
      return chargeDate
    },
    generateXeroFile(xeroData, type, exportGoCardless, lastInvoiceOfMonth) {
      const description = lastInvoiceOfMonth ? "IncMonthly" : ""
      let writeCounter = 0;
      this.xeroExportData = [];
      let goCardlessArray = [];
      let goCardlessDetails = {};
      xeroData.forEach((company) => {
        company.company_name = this.formatCompanyName(
          company.company_code,
          company.company_name
        );

        if (company.data.length > 0) {
          goCardlessDetails[company.company_id] = {};
          goCardlessDetails[company.company_id].totalAmountCombined = 0;
          let totalAmount = 0;

          //chargeDate is the invoice date + 14 days
          let chargeDate = this.getChargeDate(company.data[0].invoice_date)
          
          goCardlessDetails[company.company_id].chargeDate = chargeDate;

          //payment description is invoice number
          goCardlessDetails[
            company.company_id
          ].paymentDescription = `${company.company_code}-${company.data[0].invoice_number}`;

      
          let invoiceDatesBoundaryRef = this.getXeroDateBoundariesStr(company.company_code, 
              company.data[0].invoice_date,  lastInvoiceOfMonth)

          company.data.forEach((element) => {
            let xeroDesc = element.description + invoiceDatesBoundaryRef

            totalAmount += parseFloat(element.unit_amount);
            let tempArray = {
              ContactName: company.company_name,
              EmailAddress: null,
              POAddressLine1: null,
              POAddressLine2: null,
              POAddressLine3: null,
              POAddressLine4: null,
              POCity: null,
              PORegion: null,
              POPostalCode: null,
              POCountry: null,
              InvoiceNumber: `${company.company_code}-${element.invoice_number}`,
              Reference: null,
              InvoiceDate: element.invoice_date
                ? element.invoice_date.substring(0, 10)
                : "",
              DueDate: element.due_date
                ? element.due_date.substring(0, 10)
                : "",
              Total: null,
              InventoryItemCode: null,
              Description: xeroDesc,
              Quantity: 1,
              UnitAmount: parseFloat(element.unit_amount),
              Discount: null,
              AccountCode: element.account_code,
              TaxType: element.tax_type,
              TaxAmount: null,
              TrackingName1: element.tracking_name1,
              TrackingOption1: element.tracking_option1,
              TrackingName2: null,
              TrackingOption2: null,
              Currency: null,
              BrandingTheme: null,
            };
            this.xeroExportData.push(tempArray);
          });
          totalAmount = totalAmount * 1.2;
          //round to 2 decimal places and store it on the object
          goCardlessDetails[company.company_id].totalAmountCombined =
            Math.round(totalAmount * 100) / 100;
          //begin process of creating go cardless entry for each company
          if (this.goCardlessTemplate.length > 0) {
            let goCardlessCompanyEntry = this.goCardlessTemplate.find(
              (entry) => {
                return entry.company_id == company.company_id;
              }
            );
            if (goCardlessCompanyEntry) {
              let tempGoCardless = {
                "mandate.id": goCardlessCompanyEntry.mandate_id,
                "customer.id": goCardlessCompanyEntry.customer_id,
                "customer.given_name":
                  goCardlessCompanyEntry.customer_given_name,
                "customer.family_name":
                  goCardlessCompanyEntry.customer_family_name,
                "customer.company_name":
                  goCardlessCompanyEntry.customer_company_name,
                "customer.email": goCardlessCompanyEntry.customer_email,
                "payment.amount": totalAmount,
                "payment.currency": "GBP",
                "payment.description":
                  goCardlessDetails[company.company_id].paymentDescription,
                "payment.charge_date": chargeDate,
                "payment.metadata.YOUR_CUSTOM_FIELD": null,
                "customer.metadata.custom_reference":
                  goCardlessCompanyEntry.metadata_custom_reference,
              };
              goCardlessArray.push(tempGoCardless);
            }
          }
        }
      });
      if (this.xeroExportData.length > 0 && this.exportXeroFile) {
        var elt3 = this.xeroExportData;
        var wb3 = XLSX.utils.book_new();
        var ws3 = XLSX.utils.json_to_sheet(elt3);
        XLSX.utils.book_append_sheet(wb3, ws3, "Xero Data");
        // XLSX.writeFile(wb3, "XeroData." + type);
        writeCounter++;
        setTimeout(() => {
          this.writeFile(wb3, `XeroData${description}.${type}`);
        }, 1 + 500 * writeCounter);
      }
      if (goCardlessArray.length > 0 && exportGoCardless) {
        var wb4 = XLSX.utils.book_new();
        var ws4 = XLSX.utils.json_to_sheet(goCardlessArray);
        XLSX.utils.book_append_sheet(wb4, ws4, " Go Cardless");
        // XLSX.writeFile(wb4, "GoCardless." + type);
        writeCounter++;
        setTimeout(() => {
          this.writeFile(wb4, "GoCardless." + type);
        }, 1 + 500 * writeCounter);
      }
    },
    writeFile(wb, fileName) {
      XLSX.writeFile(wb, fileName);
    },
    getSalesSummaryGrouping(columnName) {
      if (!columnName) return "";
      const warehousing = ["inbound", "pick pack", "vas", "customer minimums"];
      const distribution = [
        "parcel distribution",
        "pallet distribution",
        "freight",
        "postage",
        "parcels hermes",
        "duty and taxes",
        "distribution revenue - parcels amazon"
      ];
      const admin = [
        "administration & services",
        "order admin",
        "fusion revenue",
      ];
      let lowerCaseCol = columnName.toLowerCase();
      if (warehousing.includes(lowerCaseCol)) {
        return "Warehousing";
      } else if (distribution.includes(lowerCaseCol)) {
        return "Distribution";
      } else if (admin.includes(lowerCaseCol)) {
        return "Administration & Services";
      } else {
        return columnName;
      }
    },
    sortFunc(array) {
      return array.slice().sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
      });
    },
    renderCurrency(value) {
      if (value === null) {
        value = 0.0;
      }
      return `£${parseFloat(value).toFixed(2)}`;
    },
    setCompanyCode() {
      let company = this.companies.find(
        ({ name }) => name === this.companyName
      );
      if (company) {
        this.getCompanyData(company.id);
        this.query.companyCode = company.code;
        this.query.companyId = company.id;
      }
    },
    enableFileExport() {
      if (this.query.setInvoiceDate) {
        this.invoiceDateSet = false;
      }
    },
    // Company Data
    getCompaniesData() {
      // Use the BillingSnap to call the getBillingData() method
      return CompaniesUtil.getSnapCompanies()
        .then(
          ((companies) => {
            this.$set(this, "companies", companies);
            this.companies = this.sortFunc(this.companies);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    async getCompanyData(companyID) {
      // Use the BillingSnap to call the getBillingData() method
      CompaniesUtil.getCompany(companyID)
        .then(
          ((singleCompany) => {
            "singleCompany", singleCompany;
            this.selectedCompaniesFull.push(singleCompany);
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return;
        });
    },
    async getInvoiceNumbers() {
      // Use the BillingSnap to call the getBillingData() method
      return CommitedData.getInvoiceNumbers()
        .then(
          ((invoiceNumbers) => {
            this.$set(this, "invoiceNumbers", invoiceNumbers);
            return true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
          return false;
        });
    },
    checkIfFinishedLoading(completedRequests, totalRequests) {
      if (completedRequests >= totalRequests) {
        this.loading = false;
      }
    },
    getCompanyBillingXero(companyIds, invoiceNumber, monthly) {
      let params = {
        companyIds: companyIds,
        invoiceNumber: invoiceNumber,
      };
      if (monthly) {
        params.monthly = true;
      }
      return CommitedData.getCompanyBillingXero(params).then((response) => {
        // group data by company
        let groupedData = [];
        for (const companyId of companyIds) {
          let company = this.selectedCompanies.find(
            (comp) => comp.id == companyId
          );
          if (!company) continue;
          let companyData = response.filter(
            (row) => row.company_id == companyId
          );
          let obj = {
            company_code: company.code,
            company_name: company.name,
            company_id: company.id,
            data: companyData,
          };
          groupedData.push(obj);
        }
        return groupedData;
      });
    },
    getCompanyBillingCommitted(companyIds, invoiceNumber, monthly) {
      let params = {
        companyIds: companyIds,
        invoiceNumber: invoiceNumber,
      };
      if (monthly) {
        params.monthly = true;
      }
      return CommitedData.getCompanyBillingCommitted(params).then(
        (response) => {
          // group data by company
          let groupedData = [];
          for (const companyId of companyIds) {
            let company = this.selectedCompanies.find(
              (comp) => comp.id == companyId
            );
            if (!company) continue;
            let companyData = response.filter(
              (row) => row.company_id == companyId
            );
            let obj = {
              company_code: company.code,
              company_name: company.name,
              company_id: company.id,
              data: companyData,
            };
            groupedData.push(obj);
          }
          return groupedData;
        }
      );
    },
    async createCompanyInvoice() {
      this.companyBilliingData = [];
      //clear data so it reloads with new one instead of simply adding to it
      this.xeroBillingData = [];
      let chargeCodes = await this.getChargeCodes("");
      if (!chargeCodes) {
        this.snackbar = true;
        this.text = `Failed to get charge codes`;
        this.loading = false;
        return;
      }

      this.loading = true;
      let companyIds = this.selectedCompanies.map((comp) => comp.id);

      this.xeroBillingData = await this.getCompanyBillingXero(
        companyIds,
        this.selectedInvoice
      );
      this.companyBilliingData = await this.getCompanyBillingCommitted(
        companyIds,
        this.selectedInvoice
      );
      this.currentLoadedInvoiceNumber = this.selectedInvoice;
      this.getGoCardlessTemplate();
      this.loading = false;
    },
    async getGoCardlessTemplate() {
      CommitedData.getGoCardlessTemplate()
        .then(
          ((response) => {
            "response", response;
            this.goCardlessTemplate = response;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          return;
        });
    },
    async finalCommitBillingData() {
      this.loading = true;
      let requestBody = [];
      let todaysDate = new Date();
      let todaysDateStr = todaysDate.toISOString().slice(0, 10);

      let tempString = "";

      this.companyBilliingData.forEach((companyData) => {
        let tempArray = {
          companyCode: companyData.company_code,
          dataLength: companyData.data.length,
          invoiceNumber: this.selectedInvoice,
        };
        requestBody.push(tempArray);

        // submit withdrawal request for company billing credit
        var totalCharges = 0;
        companyData.data.forEach((row) => {
          // only insert charge if it has not been committed yet
          if (!row.committed) {
            totalCharges += parseFloat(row.total_charge);
          }
        });
        // only insert credit charges if its above 0
        if (totalCharges > 0) {
          let compCreditObj = {
            company_id: companyData.company_id,
            deposit: "",
            withdrawal: totalCharges,
            reference: "Invoice " + this.selectedInvoice,
            date: todaysDateStr,
          };
          BillingSnap.insertCompanyBillingCredit(compCreditObj);
        }
      });

      CommitedData.checkDataThenFinalCommit(requestBody)
        .then((response) => {
          // send revenue email to management staff
          CommitedData.sendRevenuePerPalletByCompany(this.selectedInvoice);
          if (response.successMessage) {
            response.successMessage.forEach((message) => {
              tempString += `${message} `;
            });
          }
          if (response.errorMessage) {
            response.errorMessage.forEach((message) => {
              tempString += `${message} `;
            });
          }

          this.text = `${tempString}`;
          this.loading = false;
          this.snackbar = true;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error}`;
          this.loading = false;
          return;
        });
    },
    async loadInitialData() {
      this.loading = true;
      this.companies = await this.$root.sharedData.getCompanies();
      let invoiceNumber = await this.getInvoiceNumbers();
      this.getAllCompanyBillingSettings();
      if (this.companies.length > 0 && invoiceNumber) {
        this.loading = false;
        this.text = `Please choose Companies and Invoice Number to view Data.`;
        this.snackbar = true;
      }
    },
  },
  created() {
    this.loadInitialData();
  },
  computed: {
    monthlyCompanies() {
      if (!this.companyBillingSettings || this.companyBillingSettings < 1)
        return [];
      let comps = this.companyBillingSettings.filter(
        (comp) => comp.monthly_billing
      );
      return comps.map((comp) => comp.company.code);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.errorMessage {
  font-size: smaller;
  margin-top: 12px;
  p {
    margin-bottom: 0px;
  }
}
</style>
